// @import "~antd/dist/antd.css";
@import "./css/variables.scss";
@import "./css/foundation/colors.scss";
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Material+Icons+Outlined");
@import url("https://fonts.googleapis.com/css2?family=Material+Icons+Round");
@import url("https://fonts.googleapis.com/css2?family=Material+Icons+Sharp");
@import url("https://fonts.googleapis.com/css2?family=Material+Icons+Two+Tone");
// @import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  overscroll-behavior-y: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  @each $baseColor, $variants in $colors-list {
    @each $variantName, $value in $variants {
      --#{'' + $baseColor}-#{$variantName}: #{$value};
    }
  }
}
body
  .ant-select-dropdown
  .ant-select-dropdown-menu
  .ant-select-dropdown-menu-item.select-all {
  & .anticon-check {
    display: none;
  }
}

body .ant-select-dropdown-menu-item-group-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

body .ant-select-dropdown-menu-item-group {
  box-shadow: 0px 1px 0px #e0e0e0;
  padding: 8px 0px;
}

body .ant-select-dropdown-menu-item-group:last-of-type {
  box-shadow: none;
}

body .css-rubric-builder-question-options {
  width: 150px !important;
}

body .ant-time-picker-panel-input {
  pointer-events: none;
}

body .ant-calendar-footer {
  text-transform: capitalize;
}

body .ant-notification {
  & .ant-notification-notice-message {
    color: #fff;
  }

  &
    .ant-notification-notice-close
    > .ant-notification-close-x
    > .ant-notification-close-icon {
    & svg {
      & path {
        fill: #fff;
      }
    }
  }
}

body {
  div.filter__custom__date__tooltip {
    & .ant-tooltip-content {
      background-color: $color-black;
    }
  }

  div.hover-info-box {
    & .ant-tooltip-content {
      width: 465px;
      max-height: 220px;
      overflow-y: scroll;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      padding: 16px;
      background-color: var(--stone-100);
      border: 1px solid #e1deda;

      & .ant-tooltip-inner {
        color: #333333;
        box-shadow: none;
        background-color: var(--stone-100);
      }

      & .ant-tooltip-arrow::before {
        display: none;
      }
    }
  }

  div.email-digest-settings-tooltip {
    & .ant-tooltip-inner {
      background-color: #ffffff !important;
      color: #4e4e4e !important;
      width: 368px;
      padding: 16px;
    }
    & .ant-tooltip-arrow {
      display: none;
    }
  }

  .level-notification {
    > svg {
      padding: 6px;
      height: 32px;
      width: 32px;
      margin-top: 5px;

      path {
        fill: #4f4f4f;
      }
    }
  }

  .level-notification:hover > svg {
    border-radius: 50%;
    background-color: #e6eff9;

    path {
      fill: #0b4b93;
    }
  }

  .level-notification:active {
    svg path {
      fill: rgb(11, 75, 147);
    }

    svg {
      border-radius: 50%;
      background-color: rgba(11, 75, 147, 0.1);
    }
  }

  .drawer-no-overflow .ant-drawer-wrapper-body {
    overflow: hidden;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body .interaction-history-user-filter {
  .ant-select-dropdown-menu-item {
    padding: 6px 12px;
    font-family: roboto;
    font-weight: 400;
    &.ant-select-dropdown-menu-item-selected {
      background: #e6f3fb;
      font-weight: 500;
    }
  }
  .ant-select-dropdown-menu-vertical {
    padding: 0;
  }
  .ant-select-dropdown-menu-item-group {
    padding: 8px 0 0;
    box-shadow: none;
  }
  .ant-select-dropdown-menu-item-group-title {
    height: 28px;
    line-height: 28px;
    font-family: roboto;
    font-weight: 400;
  }
}
.home-dashboard-select {
  width: 150px !important;

  & .ant-select-dropdown-menu {
    max-height: 400px !important;
  }
}

.clickable-icon {
  padding: 8px;
  border-radius: 4px;
  &:hover {
    background-color: #efefef;
  }
}
.modal-border-none {
  .ant-modal-content {
    border-radius: 0 !important;
  }
}
.ant-table-scroll-auto .ant-table-body {
  overflow: auto !important;
}

.ant-table-scroll-auto .ant-spin-nested-loading > div > .ant-spin {
  top: auto;
  bottom: 50px;
}

.custom-notification-snackbar {
  display: flex !important;
  align-items: center !important;
  background: #333333 !important;
  min-width: 120% !important;
  justify-content: space-between !important;

  .ant-notification-notice-content {
    .ant-notification-notice-with-icon {
      display: flex !important;
      align-items: center !important;

      .ant-notification-notice-icon {
        line-height: 20px !important;
      }

      .ant-notification-notice-message {
        font-size: 14px !important;
        text-wrap: balance !important;
        padding-right: 4px !important;
        margin-bottom: 0 !important;
        margin-left: 36px !important;
      }

      .ant-notification-notice-description {
        display: none !important;
      }
    }
  }

  .ant-notification-notice-close {
    position: static !important;
  }
}

.ant-notification-bottomCenter {
  left: 0;
  margin-right: auto !important;
  right: 0;
  margin-left: auto !important;
  top: auto;
}

.hide-scrollbar {
  /*
  This hides scrollbar, doesn't have any impact  on the scrolling functionality though
  */
  // For chrome, safari and opera
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; // IE & Edge
  scrollbar-width: none; // Firefox
}

div.tox.tox-silver-sink.tox-tinymce-aux {
  & .tox-notifications-container {
    display: none;
  }
}
