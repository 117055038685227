$color-white: #ffffff;
$color-black : #000000;
$color-red: red;
$color-blue: #0B4B93;
$color-primary: #FF7D04;


$font-primary: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;

$black-1: #443F3F;
$black-2: #212121;
$black-3: #171717;
$black-4: #4F4F4F;